export function receivedData(state, data) {
  state.loadedPages = [data.pageNumber];
  state.logs = data.data;
  state.logList = data.data['hydra:member'];
  state.totalItems = data.data['hydra:totalItems'];
  state.isLoading = false;
  state.pending = false;
  state.initialized = true;
}

export function initPage(state, nextPage) {
  state.logs = null;
  state.logList = null;
  state.isLoading = true;
  state.currentPage = nextPage;
  state.loadedPages = [nextPage];
}

export function updateFilter(state, filter) {
  state.filter = filter;
}

export function updateMessageFilter(state, newMessageFilter) {
  state.messageFilter = newMessageFilter;
}

export function updateFlowExecutionFilter(state, newflowExecutionFilter) {
  state.flowExecutionFilter = newflowExecutionFilter;
}

export function updateSorting(state) {
  state.sort === "desc" ? state.sort = "asc" : state.sort = "desc";
  localStorage.setItem('logSort', state.sort);
}

export function reloadPage(state, page) {
  state.pending = true;
  state.currentPage = page;
  state.loadedPages = [page];
}

export function updatePage(state, page) {
  state.currentPage = page;
  state.loadedPages.push(page);
}

export function UPDATE_LOGS(state, logs) {
  state.logs = logs['hydra:member'];
  state.totalItems = logs['hydra:totalItems'];
}

export function addLogs(state, data) {
  if ( typeof data.data['hydra:member'] !== 'undefined' ) {
    data.data['hydra:member'].forEach( (item) => {
      state.logs['hydra:member'][data.start] = item;
      data.start++;
    })
  }
  state.pending = false;
}

export function refreshLogs(state, data) {
  state.totalDiff = state.totalItems < 1 ? 0 : data?.data['hydra:totalItems'] - state.totalItems;
  state.totalItems = data.data['hydra:totalItems'];
  state.currentPage = data.page;
  state.loadedPages = [data.page];
}

export function pending(state) {
  state.pending = true;
}

export function clearDiff(state) {
  state.totalDiff = 0;
}
