import { reactive, watch } from 'vue'
import { useRouter, useRoute }  from 'vue-router'
import paginator from 'components/Paginator.vue'

export default function usePaginator(initialPage, initialItemsPerPage) {
  const $route = useRoute();
  const $router = useRouter();

  const pagination =  reactive({
    page: initialPage ?? 1,
    itemsPerPage: initialItemsPerPage ?? 10,
    pageCount: 0,
    totalItems: 0
  })

  /**
   * Update the current route's query with the pagination
   * object values. If using this method, make sure you pass in an object
   * similar to the pagination property we use here or to be safe, just passed in the pagination
   * object
   *
   * @param page {number}
   * @param itemsPerPage {number}
   */
  async function updateRouteQuery({ page, itemsPerPage }) {
    const routeQuery = { ...$route.query }
    if (page !== 0) routeQuery.page = page.toString();
    if (itemsPerPage !== 0) routeQuery.size = itemsPerPage.toString();

    await $router.push({ query: routeQuery })
  }

  /**
   * Build an object to be used when making backend pagination
   *
   * @param page {number}
   * @param itemsPerPage {number}
   *
   * @returns {{itemsPerPage, page}}
   */
  function buildQueryData({ page, itemsPerPage }) {
    return {
      page,
      itemsPerPage
    }
  }

  /**
   * Removes the query altogether
   *
   *
   * @returns void
   *
   */
  function clearQuery() {
    delete $route.query.page;
    delete $route.query.size;
    updateRouteQuery({ page: 0, itemsPerPage: 0 })
  }

  /**
   * Read the pagination value from the current route's
   * query and set it as the pagination object's value
   *
   * @param pager {object}
   */
  function readFromQuery(pager) {
    const query = $route.query

    pager.page = query.page > 0 ? Number(query.page) : 1
    pager.itemsPerPage = query.size > 0 ? Number(query.size) : pager.itemsPerPage ? pager.itemsPerPage : 10
  }

  function routeQueryObserver(callback) {
    // sanity check: a callback should be present
    if (!callback) {
      console.error('No callback passed on the route query observer')
      return
    }

    watch(
      () => $route.query,
      (newValue) => {
        callback(newValue)
      }
    )
  }

  return {
    pagination,

    updateRouteQuery,
    clearQuery,
    readFromQuery,
    routeQueryObserver,
    buildQueryData
  }
}
