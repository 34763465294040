import client from '../helpers/authenticated-axios'

export const logService = {
  getLogs(page, itemsPerPage, filter, messageFilter, flowExecutionFilter, sort, callback) {
    let filters = '';
    if (messageFilter) filters += '&message=' + messageFilter;
    if (filter?.length > 0) filters += '&level_name[]=' + filter?.join('&level_name[]=');
    if (flowExecutionFilter) filters += '&flow_execution_id=' + flowExecutionFilter;
    client().get('/log_entries?page=' + page + '&itemsPerPage=' + itemsPerPage + filters + '&order[datetime]=' + sort).then((response) => {
      callback(response.data);
    }).catch((error) => {
      // TODO: Why is there an 'Uncaught (in promise) TypeError: callback is not a function' error thrown? Disable callback for now.
      //callback(() => error);
    });
  }
}
