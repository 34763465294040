import client from '../helpers/authenticated-axios'

export const flowExecutionService = {
  getFlowExecutions(page, itemsPerPage, flow, filterUrl, callback) {
    let flowParam = '';
    if (flow) flowParam = '&flow=';
    client().get('/flow_executions?order[created_at]=desc&page=' + page + '&itemsPerPage=' + itemsPerPage + flowParam + filterUrl).then((response) => {
      callback(response.data)
    }).catch((error) => {
      callback(error.response);
    });
  },
  getFlowExecutionDetails(flowId, callback) {
    client().get('/flow_executions/' + flowId).then((response) => {
      callback(response.data)
    }).catch((error) => {
      callback(error.response);
    });
  }
}
