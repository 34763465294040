<script>
  import usePaginator from 'src/composables/usePaginator'
  import ItemsPerPage from 'components/ItemsPerPage.vue'
  import Pagination from 'components/Pagination.vue'

  export default {
    name: 'Paginator',

    components: {
      ItemsPerPage,
      Pagination
    },

    props: {
      page: {
        type: Number,
        required: true
      },

      itemsPerPage: {
        type: Number,
        required: true
      },

      totalPages: {
        type: Number,
        required: false,
        default: 0
      },

      position: {
        type: String,
        required: false,
        default: 'right'
      },

      paginationOptions: {
        type: Array,
        required: false,
        default: null
      }
    },

    emits: [
      'update:page',
      'update:itemsPerPage',
      'manual-update'
    ],

    setup(props, { emit }) {
      const { pagination, readFromQuery, routeQueryObserver } = usePaginator()

      pagination.page = props.page
      pagination.itemsPerPage = props.itemsPerPage

      routeQueryObserver((value) => {
        const itemsPerPage = value.size ? Number(value.size) : null
        const page = value.page ? Number(value.page) : null

        if (itemsPerPage !== pagination.itemsPerPage || page !== pagination.page) {
          readFromQuery(pagination)
          emit('manual-update', {
            page: pagination.page,
            itemsPerPage: pagination.itemsPerPage
          })
        }
      })

      return {
        pagination,
        readFromQuery
      }
    },

    computed: {
      positionClass() {
        if (this.position === 'right') {
          return {
            container: 'flex justify-end',
            pagination: 'q-mr-md',
            items: ''
          }
        }
      }
    },

    methods: {
      handlePageUpdate(page) {
        this.$emit('update:page', page)
      },

      handleItemsPerPageUpdate(itemsPerPage) {
        this.$emit('update:itemsPerPage', itemsPerPage)
      }
    },

    watch: {
      page(value) {
        if (value !== this.pagination.page) this.pagination.page = value
      },

      itemsPerPage(value) {
        if (value !== this.pagination.itemsPerPage) this.pagination.itemsPerPage = value
      }
    }
  }
</script>

<template>
  <div :class="positionClass.container" v-if="totalPages > 0"> <!-- 0 = loading state -->
    <pagination
      v-if="totalPages > 1"
      v-model="pagination.page"
      :pages="totalPages"
      :class="positionClass.pagination"
      @update:model-value="handlePageUpdate"
    />
    <items-per-page
      v-model="pagination.itemsPerPage"
      :class="positionClass.items"
      @update:model-value="handleItemsPerPageUpdate"
      v-bind="paginationOptions !== null ? { 'pagination-options': paginationOptions } : {}"
    />
  </div>
</template>
