<script>
  import { defineComponent, ref } from 'vue'
  import JsonEditor from 'components/JsonEditor.vue'
  import LoadingIndicator from 'components/LoadingIndicator'
  import JobDispatcherMappingModal from '../JobDispatcherMappingModal/JobDispatcherMappingModal'
  import DeleteJobDispatcherMappingModal from '../JobDispatcherMappingModal/DeleteJobDispatcherMappingModal'
  import { flowService } from 'src/services'


  export default defineComponent({
    name: 'JdmItem',

    components: {
      LoadingIndicator,
      JsonEditor,
      JobDispatcherMappingModal,
      DeleteJobDispatcherMappingModal
    },

    props: ['jdm', 'flow'],

    emits: ['reload'],

    data() {
      return {
        showJdmEditModal: false,
        showJdmDeleteModal: false,
        triggerModal: '',
        isLoading: false,
        editJdmModal: ref(false),
        localJdmDispatchCondition: JSON.stringify(this.jdm.dispatchCondition, null, 2),
        localJdmModuleConfig: JSON.stringify(this.jdm.configuration, null, 2),
      }
    },

    methods: {
      handleJdmUpdated(data) {
        this.showJdmEditModal = false

        this.$emit('reload', data)
      },

      handleJdmDeleted() {
        this.isLoading = true;
        if (this.jdm.id) {
          this.$emit('reload', {deleted: true, jdmId: this.jdm.id})
        }
        this.isLoading = false;
      },

      highlighter(code) {
        return highlight(code, languages.js); // languages.<insert language> to return html with markup
      },

      async openJdmDeleteModal() {
        this.showJdmDeleteModal = true
        return
      },

      async openJdmConfig() {
        // this.showJdmEditModal = true
        // return
        if (this.jdm.id) {
          this.$router.push({ name: 'JdmConfigBuilder', params: { id: this.$route.params.id, jdmId: this.jdm.id } })
        }
      },
      onStatusChange(value) {
        this.isLoading = true;
        flowService.toggleJobDispatcherMappingStatus(this.jdm.id, value)
        .then(e => {
          if (e.data.success) {
            if (e.data.message === 'jdmEnabled') this.$store.dispatch('alert/success',  'flow.overview.detail.jdmToggleEnabled');
            if (e.data.message === 'jdmDisabled') this.$store.dispatch('alert/success',  'flow.overview.detail.jdmToggleDisabled');
          }
        })
        .finally(() => this.isLoading = false)
        .catch(e => {
          if (value) this.$store.dispatch('alert/error',  'flow.overview.detail.jdmToggleEnabledFailure');
          if (!value) this.$store.dispatch('alert/error',  'flow.overview.detail.jdmToggleDisabledFailure');

          this.jdm.active = !this.jdm.active;
        });
      },
    },

    computed: {
      localDispatchConditionFormatted: {
        get: function() {
          return this.localJdmDispatchCondition;
        },
        set: function(val) {
          this.localJdmDispatchCondition = val;
        }
      },

      localModuleConfigFormatted: {
        get: function() {
          return this.localJdmModuleConfig;
        },
        set: function(val) {
            this.localJdmModuleConfig = val;
        }
      },

      activeToggleStatus() {
        return this.isLoading || this.flow.isRunning;
      },
    }
  })
</script>

<template>
<div class="q-mt-md relative-position">
  <loading-indicator v-if="isLoading" />
  
  <div
    class="app-jdm-item app-bg2 flex q-pa-sm"
    data-cy="buttonEditJdmModal"
    @click="openJdmConfig"
  >
    <div class="col-auto q-px-sm flex center text-h6">{{ jdm.id }}</div>
    <div class="col q-px-sm">
      <div class="app-text-weight-semibold app-text-break-all">{{ $props.jdm.messageFqcn }}</div>
      <div>{{ $t('flow.overview.detail.modal.dispatchCondition') }}: {{ $props.jdm.dispatchCondition }}</div>
    </div>
    <div class="col-auto q-px-sm flex right">
      <q-toggle v-model="jdm.active" @update:model-value="onStatusChange" :disable="activeToggleStatus"/>
    </div>
    <q-badge 
      v-if="$props.jdm.dispatchCondition.length !== 0"
      rounded
      color="yellow"
      class="self-start"
     />
    <job-dispatcher-mapping-modal
      v-model="showJdmEditModal"
      :job-dispatcher-mapping="jdm"
      :flow="flow"
      @updated="handleJdmUpdated"
    />
    <q-btn
      @click.capture.stop="openJdmDeleteModal"
      :disabled="isLoading"
      class="app-delete-btn app-delete-jdm self-start self-md-stretch q-pa-sm"
      icon-right="delete"
      flat dense unelevated
    />
    <delete-job-dispatcher-mapping-modal
      v-model="showJdmDeleteModal"
      :job-dispatcher-mapping="jdm"
      @delete="handleJdmDeleted"
    />
  </div>
</div>
</template>

<style lang="scss">
  .app-jdm-item {
    background: $background2;
    border: $layout-border;
    border-radius: $generic-border-radius;
    &:hover {
      border: 1px solid $secondary;
      cursor: pointer;
      z-index: 1;
    }
  }
  body.body--dark {
    .app-jdm-item {
      background: $dark;
      border: 1px solid $separator-dark-color
    }
  }
</style>
