import { logService } from "src/services";

export function initProps({ dispatch, commit, state }, {messageFilter, flowExecutionFilter}) {
  commit('updateMessageFilter', messageFilter);
  commit('updateFlowExecutionFilter', flowExecutionFilter);
}

export function updateLogs({ dispatch, commit, state }, logs) {
  commit('UPDATE_LOGS', logs);
}

export function updateFilter({ dispatch, commit, state }, filter) {
  commit('updateFilter', filter);
}

export function loadLogs({ dispatch, commit, state }, {pageNumber, reload, flowExecutionFilter}) {
  reload !== true ? commit('initPage', pageNumber) : commit('reloadPage', pageNumber);
  logService.getLogs(state.currentPage, state.itemsPerPage, state.filter, state.messageFilter, flowExecutionFilter, function(data) {
    commit('receivedData', { data, pageNumber, reload })
  })
}

/*export function loadLogs({ dispatch, commit, state }, {pageNumber, itemsPerPage, reload, flowExecutionFilter}) {
  reload !== true ? commit('initPage', pageNumber) : commit('reloadPage', pageNumber);
  logService.getLogs(pageNumber, itemsPerPage, state.filter, state.messageFilter, flowExecutionFilter, function(data) {
    commit('receivedData', { data, pageNumber, reload })
  })
}*/

export function updateLevelFilter({ dispatch, commit, state }, {pageNumber, level, flowExecutionFilter}) {
  commit('initPage', pageNumber);
  commit('updateFilter', level);
  logService.getLogs(state.currentPage, state.itemsPerPage, state.filter, state.messageFilter, flowExecutionFilter, function(data) {
    commit('receivedData', { data, pageNumber})
  })
}

export function switchSorting({ dispatch, commit, state }, {flowExecutionFilter}) {
  commit('initPage', 1);
  commit('updateSorting');
  let pageNumber = 1;
  logService.getLogs(state.currentPage, state.itemsPerPage, state.filter, state.messageFilter, flowExecutionFilter,function(data) {
    commit('receivedData', { data, pageNumber})
  })
}

export function updateMessageFilter({ dispatch, commit, state }, {newMessageFilter, flowExecutionFilter}) {
  commit('initPage', 1);
  commit('updateMessageFilter', newMessageFilter);
  let pageNumber = 1;
  logService.getLogs(state.currentPage, state.itemsPerPage, state.filter, state.messageFilter, flowExecutionFilter,function(data) {
    commit('receivedData', { data, pageNumber})
  })
}

export function addLogs({ dispatch, commit, state }, { page, start, flowExecutionFilter }) {
  if (!state.loadedPages.includes(page)) {
    commit('pending');
    commit('updatePage', page);
    logService.getLogs(page, state.itemsPerPage, state.filter, state.messageFilter, flowExecutionFilter,function(data) {
      commit('addLogs', { data, start })
    })
  }
}

export function refreshLogs({ dispatch, commit, state }, { page, start, flowExecutionFilter }) {
  commit('pending');
  logService.getLogs(page, state.itemsPerPage, state.filter, state.messageFilter, flowExecutionFilter,function(data) {
    commit('refreshLogs', { page, data });
    commit('addLogs', { data, start });
  })
}

export function clearDiff({ dispatch, commit, state }) {
  commit('clearDiff');
}
